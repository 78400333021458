<!-- exports.install = function (Vue, options) {
   Vue.prototype.url = function (){//全局函数1
   console.log(options)
		return 'http://localhost:1116/'
    };
    Vue.prototype.text2 = function (){//全局函数2
    alert('执行成功2');
    };
}; -->

<script>
import { def } from "@vue/shared"

    const url = "https://apiyun.hxven.com/"
    //  const url = 'http://127.0.0.1:807/'
    export default{
        url,
    }
</script>