
<style>
#app, body, html {
	width: 100%;
	height: 100%;
}
.login{
    width: 100%;
    height: 100%;
    background-image: url('https://file.iviewui.com/admin-pro-dist/img/body.8aa7c4a6.svg');
    background-size: cover;
    background-position: center;
    position: relative;
}
  .login-con {
	flex: 1;
	padding: 32px 0;
	text-align: center;
	width: 384px;
	margin: 0 auto;
  }
  .page-account-auto-login {
      margin-bottom: 24px;
      text-align: left;
  }
  .page-account-auto-login a {
      float: right;
  }
  .page-account-other {
      margin: 24px 0;
      text-align: left;
  }
  .page-account-other span {
      font-size: 14px;
  }
  .page-account-other img {
      width: 24px;
      margin-left: 16px;
      cursor: pointer;
      vertical-align: middle;
      opacity: .7;
      transition: all .2s ease-in-out;
  }
  .page-account-register {
      float: right;
  }
  .ivu-row {
		flex-flow: inherit !important;
  }
	.ivu-row img{
		height: 30px;
		position: relative;
		top: 2px;
	}
</style>
<template>
  <div class="login">
    <div class="login-con">
      <Card icon="log-in" title="欢迎登录" :bordered="false">
        <div class="form-con">
			<login-form ></login-form>
			<div class="page-account-other">
				<span>其它登录方式</span>
				<img src="https://file.iviewui.com/admin-pro-dist/img/icon-social-wechat.c69ec08c.svg" alt="wechat">
				<img src="https://file.iviewui.com/admin-pro-dist/img/icon-social-qq.2cf4276d.svg" alt="qq">
				<img src="https://file.iviewui.com/admin-pro-dist/img/icon-social-weibo.cbf658a0.svg" alt="weibo">
				<a href="/register" class="page-account-register">注册账户</a>
			</div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import LoginForm from './login-form/index.js'
// import { mapActions } from 'vuex'
export default {
	components: {
		LoginForm
	},
	methods: {
		// ...mapActions([
		// 	'handleLogin',
		// 	'getUserInfo'
		// ]),
		
	}
}
</script>

<style>
</style>