import Vue from 'vue'
import VueRouter from 'vue-router';
import login from "components/Login.vue";
import Home from "components/Home.vue";
import welcome from "components/welcome.vue";
import system from "./modules/system.js";
import cooperation from "./modules/cooperation.js";
import finance from "./modules/finance.js";
import withdrawal from "./modules/withdrawal.js";
import promoter from "./modules/promoter.js";
import config from "./modules/config.js";
import salary_payment from "./modules/salary_payment.js";


var constantRoutes = []
const common = [
	{path:'/welcome',component:welcome,name:'welcome'},
]
common.forEach(item=>{
	constantRoutes.push(item)
})
system.forEach(item=>{
	constantRoutes.push(item)
})
cooperation.forEach(item=>{
	constantRoutes.push(item)
})
finance.forEach(item=>{
	constantRoutes.push(item)
})
withdrawal.forEach(item=>{
	constantRoutes.push(item)
})
promoter.forEach(item=>{
	constantRoutes.push(item)
})
salary_payment.forEach(item=>{
	constantRoutes.push(item)
})
config.forEach(item=>{
	constantRoutes.push(item)
})
const routes = [
	{path:"*",component: () => import('components/common/404.vue')},
	{path:'/login',component:login,name:'login'},
	{path:'/',redirect:"/welcome"},
	{path:'/home',component:Home,name:"home",children:constantRoutes},
]

const router = new VueRouter({
      routes 
})

Vue.use(VueRouter); 
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
	document.title = '总后台'
	document.icon = 'icon-lock'
	const isLogin = sessionStorage.getItem('isLogin');
	if (to.name == 'login') {
		if (isLogin == "true") {
		  next({ name: 'Index'});
		} else {
		  next();
		}
	} else { 
		if (isLogin == "true") {
		  next(); 
		} else {
			
		  next({ name: 'login'});
		}
	}
});

export default router;