<template>
	<div>
		<el-form :model="formData"  v-loading="loading" :rules="rules" ref="ruleForm" status-icon label-width="100px">
			<el-form-item label="服务商名称" prop="name">
				<el-input type="text" placeholder="请输入服务商名称" v-model="formData.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="服务商地址" prop="address">
				<el-cascader filterable  placeholder="请选择服务商地址" v-model="formData.address" :options="regionData" :props="{value:'name',label:'name'}"></el-cascader>
			</el-form-item>
			<el-form-item label="服务商logo" prop="logo">
				<el-upload class="avatar-uploader" :action="url+'api/imgFile'" :show-file-list="false" :on-success="logoSuccess" :before-upload="logoUpload"><img v-if="formData.logo" :src="logo" class="avatar"><i v-else class="el-icon-plus avatar-uploader-icon"></i></el-upload>
			</el-form-item>
			<el-form-item label="联系人" prop="full_name">
				<el-input type="text" placeholder="请输入联系人" v-model="formData.full_name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" prop="phone">
				<el-input type="text" placeholder="请输入联系电话" v-model="formData.phone" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="服务商简介" prop="introduction">
				<el-input type="textarea" :autosize="{ minRows: 8, maxRows: 2}" placeholder="请输入服务商简介"  v-model="formData.introduction" maxlength="500" show-word-limit> </el-input>
			</el-form-item>
			<el-form-item label="登录账户" prop="username">
				<el-input type="text" placeholder="请输入登录账户" v-model="formData.username" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
import { handleError } from "@vue/runtime-core";
export default {
	props:['id'],
	data () {
		return {
			formData:{
				name:"",
				address:"",
				logo:"",
				phone:"",
				introduction:"",
				username:"",
				full_name:""
			},
			logo:"",
			loading:true,
			url:this.common.url,
			regionData:[],
			industryData:[],
			rules:{
				name:[
					{required: true, message: '请输入服务商名称', trigger: 'blur' },
					{max:40, message: '长度在40个字符', trigger: 'blur' }
				],
				address:[
					{required: true, message: '请选择服务商地址'}
				],
				full_name:[
					{required: true, message: '请输入联系人', trigger: 'blur' },
					{max:30, message: '长度在30个字符', trigger: 'blur' }
				],
				
				logo:[
					{required: true, message: '请上传图片', trigger: 'blur'}
				],
				phone:[
					{required: true, message: '请输入联系电话', trigger: 'blur'},
					{ type: 'number',
						message: '手机号格式不正确',
						trigger: 'blur',
						transform (value) {
							var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
							if ( !phonereg.test(value)) {
								return false
							}else{
								return Number(value)
							}
							
						}
					}
				],
				introduction:[
					{required: true, message: '请输入商户简介', trigger: 'blur'}
				],
				username:[
					{required: true, message: '请输入登录账户', trigger: 'blur'},
					{min:2, message: '长度在2个字符', trigger: 'blur' },
					{max:50, message: '长度在50个字符', trigger: 'blur' }
				],
			}
		}
	},
	methods:{
		
		formDataPid(value) {
			if(value.length>0){
				this.formData.pid=value[0]
			}else{
				this.formData.pid=0
			}
		},
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			this.loading = true
			return request({
				url:'system/service/edit/'+this.id,
				method:"post",
				params:this.formData
			}).then(response  => {
				this.loading = false
				if(response.code==200){
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.$emit('fun', false)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
		region(){
			return request({
				url:'region',
				method:"get",
			}).then(response  => {
				if(response.code!=200){
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}else{
					this.regionData = response.data
				}
			}).then(err => {
				if(err){
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},

		logoSuccess(res, file) {
			console.log(file.raw)
			this.logo =  URL.createObjectURL(file.raw);
			this.formData.logo = res.data.image;
		},
		logoUpload(file){
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error('上传头像logo只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像logo大小不能超过 2MB!');
			}
			return isJPG && isLt2M;
		}
		
	},
	mounted() {
		
		
	},
	watch:{
		id(newValue,oldValue){
			this.loading = true
			request({
				url:'system/service/edit/'+newValue,
				method:"get",
			}).then(response  => {
				
				if(response.code!=200){
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
					this.loading = false
				}else{
					const data = response.data
					this.formData.full_name = data.full_name
					this.formData.name = data.name
					this.formData.address = data.address.split('/')
					this.formData.logo = data.logo
					this.logo = data.url+data.logo
					this.formData.phone = data.phone
					this.formData.username = data.username
					this.formData.introduction = data.introduction
					this.region()
					this.loading = false
				}
				
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		}
	}
}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>