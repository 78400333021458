<template>
    <div>
        <el-row :gutter="20">
			<el-col :span="2">
				<div class="grid-content bg-purple">
					<el-row >
						<el-button size="mini" type="primary"  icon="el-icon-plus" @click="add()">添加</el-button>
					</el-row>
					<el-row >
						<el-button size="mini" type="danger" @click="deleteAll()" icon="el-icon-delete">删除</el-button>
					</el-row>
					
				</div>
			</el-col>
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true" :model="formInline" class="demo-form-inline">
							<el-form-item label="代理名称">
								<el-input v-model="formInline.name" placeholder="代理名称"></el-input>
							</el-form-item>
							<el-form-item label="状态">
								<el-select v-model="formInline.status" placeholder="状态">
								<el-option label="开启" value="1"></el-option>
								<el-option label="关闭" value="0"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="getList()">查询</el-button>
								<el-button @click="reset">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
        <el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column align="center" prop="id" label="序号" sortable width="80"></el-table-column>
			<el-table-column align="center" prop="name" label="代理名称"></el-table-column>
			<el-table-column align="center" label="代理账号">
				<template slot-scope="scope" >
					{{scope.row.admin.username}}
				</template>
			</el-table-column>
			<el-table-column align="center" prop="phone" label="联系电话"></el-table-column>

			<el-table-column align="center" width="100"  label="头像">
				<template slot-scope="scope" >
					<el-image
					      style="width: 100px; height: 100px"
					      :src="url+scope.row.avatar" ></el-image>
				</template>
			</el-table-column>
			<el-table-column align="center" width="100"  label="状态">
				<template slot-scope="scope" >
					<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"></el-switch>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="260">
				<template slot-scope="scope">
					<el-button  @click="password(scope.row.id)" type="text" size="small"><i class="el-icon-edit"></i>配置密码</el-button>
					<el-button  @click="modify(scope.row.id)" type="text" size="small"><i class="el-icon-edit"></i>编辑</el-button>
					&nbsp;
					<el-popconfirm title="确定要删除这条数据吗" @confirm="deletes(scope.$index, data)">
						<el-button class="text-danger"  slot="reference"  type="text" size="small"><i class="el-icon-delete"></i>删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>

        <Modal v-model="router"  draggable sticky  :mask="false" @on-cancel="ModalCancel"  :title="ModalTiele">
			<router-view @routerCancel="ModalCancel"></router-view>
		</Modal>
    </div>
</template>
<script>
    import {request} from "@/library/request.js";
    export default {
        data(){
            return{
                loading:false,
				ModalTiele:"",
                data:[],
				total:0,
				pageSize:15,
				currentPage:0,
				url:this.common.url,
                router:false,
                formInline:{
					name:"",
					status:"",
				},
            }
        },
        methods:{
            getList(){
                this.loading = true
				this.$Loading.start();
				var search = {
					// limit:this.pageSize,
					// page:this.currentPage,
					searchParams:{
						'name-%%':this.formInline.name,
						'status-=':this.formInline.status,
					}
				}
				return request({
					url:'system/agent/list',
					method:"get",
					params:search
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.data = response.data.data
					this.total = response.data.total
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
            },
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
            reset(){
				this.formInline.name=""
				this.formInline.status=""
				this.getList()
			},
            add(){
                this.router = true
				this.ModalTiele="创建代理"
                this.$router.push({
					path:'/system/agent/create'
				})
            },
            ModalCancel(res){
				this.router=false
				this.$router.push({
					path:'/system/agent/list'
				})
				if(res){
					this.getList()
				}
				
			},
			changeSwitch(data){
				let param = new URLSearchParams()
				param.append('status', data.status)
				return request({
					url:'system/agent/status/'+data.id,
					method:"post",
					data:param
				}).then(response  => {
					if(response.code!=200){
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			},
			deletes(index,row){
				return request({
					url:'system/agent/delete/'+row[index].id,
					method:"post",
				}).then(response  => {
					if(response.code==200){
						row.splice(index, 1);
					}else{
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				
			},
			deleteAll(){
				const ids = []
				this.$refs.listTable.selection.forEach(function(item,index){
					ids.push(item.id)
				})
				this.$confirm('确定要删除这些数据吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
				}).then(() => {
					return request({
						url:'system/agent/delete/'+ids.join(","),
						method:"post",
					}).then(response  => {
						if(response.code==200){
							this.getList()
						}else{
							this.$Message.error(response.msg)
						}
					}).then(err => {
						if(err){
							this.loading = false
							this.$Loading.error();
							this.$Message.error("系统异常，请稍后重试")
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			modify(id){
				this.router = true
				this.ModalTiele="编辑代理"
                this.$router.push({
					path:'/system/agent/edit/'+id
				})
			},
			password(id){
				this.router = true
				this.ModalTiele="配置密码"
                this.$router.push({
					path:'/system/agent/password/'+id
				})
			}
        },
        mounted(){
			if(this.router){
				this.ModalCancel()
			}
            this.getList()
        },
		
    }
</script>
<style scoped>
</style>