import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import './plugins/iview.js'
import router from './library/route/route.js'
import axios from 'axios'
// import common from './library/common.js'
import VueWechatTitle from 'vue-wechat-title'
import 'element-ui/lib/theme-chalk/index.css';
import common from "@/library/common.vue"
Vue.use(VueWechatTitle)
Vue.use(ElementUI);

Vue.use(common);
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.common = common
// axios.defaults.baseURL = 'https://apipay.payunke.com'

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
