<template>
	<div>
		<el-form :model="formData" :rules="rules" ref="ruleForm" status-icon label-width="100px">
			<el-form-item label="密码" prop="password">
				<el-input type="password" placeholder="请输入密码" v-model="formData.password" autocomplete="off"></el-input>
			</el-form-item>
			
		</el-form>
		<div class="drawer-footer">
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
import { handleError } from "@vue/runtime-core";
export default {
	props:['id'],
	data () {
		return {
			logo:"",
			formData:{
				password:"",
			},
		
			rules:{
				password:[
					{required: true, message: '请输入密码', trigger: 'blur' },
					{min:6, message: '长度最小6个字符', trigger: 'blur' },
					{max:20, message: '长度在40个字符以内', trigger: 'blur' }
				],
			
			}
		}
	},
	methods:{
	
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			return request({
				url:'system/service/password/'+this.id,
				method:"post",
				params:this.formData
			}).then(response  => {
				if(response.code==200){
					
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.formData.password=""
							that.$emit('fun', false)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
		region(){
			return request({
				url:'region',
				method:"get",
			}).then(response  => {
				if(response.code!=200){
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}else{
					this.regionData = response.data
				}
			}).then(err => {
				if(err){
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},

		
	},

}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>