
const withdrawal = [
	{path: '/withdrawal/list',name:'withdrawal/list', component: () => import('components/withdrawal/list.vue'),children:[
		{path: '/withdrawal/create',name:'withdrawal/create', component: () => import('components/withdrawal/create.vue')},
		{path: '/withdrawal/edit',name:'withdrawal/edit', component: () => import('components/withdrawal/edit.vue')},
	]},

	
]
export default withdrawal;
