<template>


</template>

<script>
	import {request} from "@/library/request.js";
	export default {
		name:"withdrawalValidate",
		data () {
			return {
				aa:""
			}
		},
		methods: {

		},
		mounted() {
			console.log(this.aa)
			// this.request()
		}
	}
</script>

<style>


</style>
