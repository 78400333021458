import axios from 'axios'
import router from "@/library/route/route.js";
import common from "@/library/common.vue";
import { Message } from 'iview'
export function request(config) {
	const instance = axios.create({
		baseURL: common.url+"api/",
		timeout: 5000,
	})
	
	instance.interceptors.request.use(config => {
		config.headers.apiToken = sessionStorage.getItem('login-token')
		return config
	}, err => {
		console.log(err);
	})
	instance.interceptors.response.use(res => {
		if(res.data.code==401){
			Message.error({
				content:"未登录请登录！！",
				duration: 3,
				onClose:function(){
					router.push("/login");
				}
			})
			
		}else if(res.data.code==402){
			Message.error({
				content:"用户登录凭证已过期！请重新登录",
				duration: 3,
				onClose:function(){
					sessionStorage.clear()
					sessionStorage.setItem('isLogin',false);
					router.push("/login");
				}
			})
		}else if(res.data.code==202){
			Message.error({
				content:"无权访问",
				duration: 3,
				onClose:function(){
					router.push("/");
				}
			})
		}else{
			return res.data
		}
	})
	return instance(config)
}

