
<template>
  <div class="home-main">
    <el-container>
        <el-aside width="collapse" class="scroller">
            <el-menu class="el-menu-vertical" :default-active="defaultActive"  background-color="#304156" text-color="#fff" active-text-color="#409eff"  :collapse="isCollapse">
              <div class="layout-logo"><img class="img" :src="this.common.url+logo" alt=""></div>
              <template v-for="menuItem in menuList">
                <el-menu-item :index="menuItem.href" @click="href(menuItem.href)" v-if="!menuItem.children || menuItem.children.length===0">
                  <i :class="menuItem.icon"></i>
                  <span slot="title">{{menuItem.title}}</span>
                </el-menu-item>
                <el-submenu v-else :index="menuItem.title" >
                  <template slot="title">
                    <i :class="menuItem.icon"></i>
                    <span>{{menuItem.title}}</span>
                  </template>
                  <el-menu-item :index="item.href" @click="href(item.href)" v-for="(item, index) in menuItem.children" :key="index"  >{{item.title}}</el-menu-item>
                </el-submenu>
              </template>
            </el-menu>
        </el-aside>
      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <div class="left-menu">
            <i @click="isCollapse = false" style="font-size: 20px" v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i @click="isCollapse = true" v-else style="font-size: 20px" class="el-icon-s-fold"></i>

          </div>
          <div class="right-menu">
            <div style="margin-right: 20px;font-size: 20px;" @click="reload()"><i class="el-icon-refresh"></i></div>
            <div class="avatar">
              <el-avatar size="large" shape="square" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
              <div class="user-name">
                <el-dropdown @command="handleCommand">
							<span class="el-dropdown-link">
								下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>修改密码</el-dropdown-item>
                    <el-dropdown-item command="out">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </el-header>

        <el-main>
          <div class="app-container scroller">
            <router-view v-if="isRouterAlive"></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>

import {request} from "@/library/request.js";

export default {
  data(){
    return {
      logo:null,
      menuList:[],
      isCollapse: false,
      defaultActive:"",
      isRouterAlive: true,
    }
  },
  methods:{
    handleCommand(command) {
      if(command === "out"){
        this.$confirm('确定要退出？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sessionStorage.clear()
          this.$router.replace('login');
        }).catch(() => {})

      }
    },
    href(href){
      console.log(href)
      this.$router.push(href);
    },
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    },
  },
  mounted() {
    this.defaultActive = this.$route.path
    return request({
      url:'home',
      method:"get",
    }).then(response  => {
      this.menuList = response.data.menu
      this.logo = response.data.logo
    }).then(err => {
      if(err){
        this.$Loading.error();
        this.$Message.error("系统异常，请稍后重试")
      }
    })
  }

}
</script>

<style scoped >
@import url('/css/home.css');
.home-main{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .el-container{
    width: 100%;
    height: 100%;
    .el-header {
      background-color: #fff;
      color: #333;
      line-height: 50px;
      -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      z-index: 1;
    }
    .home-menu{
      height: 100%;
      overflow: auto;
      background-color: rgb(48, 65, 86);
    }
    .el-aside {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: rgb(48, 65, 86);
      .scrollbar-wrapper{
        height: 100%;
      }
      .el-menu{
        border-right:none
      }

      .layout-logo{
        width: 100%;
        background: #fff;
        height: 50px;
        .img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .el-main{
      position: relative;
      overflow: hidden;
      background: #f3f6f9;
      padding: 0px !important;
      .app-container {
        margin: 15px;
        padding: 20px;
        height: calc(100% - 40px);
        background: #fff;
        overflow-y: auto;
        border: 1px solid #eee;
        -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      }
    }
  }
}
</style>
<style>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 201px;
  min-height: 400px;
  flex-shrink: 0;
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scroller::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
</style>