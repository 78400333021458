<template>
  <Form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit">
    <FormItem prop="userName">
		<Input v-model="form.userName" size="large" prefix="ios-person" placeholder="请输入用户名"/>
    </FormItem>
    <FormItem prop="password">
		<Input type="password" size="large" prefix="ios-lock-outline" v-model="form.password" password placeholder="请输入密码"/>
    </FormItem>

	
	<FormItem>
		<!-- <div class="page-account-auto-login">
			<Checkbox v-model="automatic">自动登录</Checkbox>
			<a href="">忘记密码</a>
		</div> -->
      <Button @click="handleSubmit" size="large" type="primary" long>登录</Button>
    </FormItem>
	<Spin size="large" fix v-if="spinShow"></Spin>
  </Form>
  
</template>
<script>
import {request} from "../../library/request.js";
	
export default {
	name: 'LoginForm',
	props: {
		userNameRules: {
			type: Array,
			default: () => {
				return [
					{ required: true, message: '账号不能为空', trigger: 'blur' }
				]
			}
		},
		passwordRules: {
			type: Array,
			default: () => {
				return [
					{ required: true, message: '密码不能为空', trigger: 'blur' }
				]
			}
		},
		imagecodeRules: {
			type: Array,
			default: () => {
				return [
					{ required: true, message: '验证码不能为空', trigger: 'blur' }
				]
			}
		}
	},
	data () {
		return {
			spinShow:false,
			automatic:true,
			Imgcode:"1231",
			form: {
				userName: '哈哈！~~',
				password: '111111',
				imagecode: ''
			}
		}
	},
	computed: {
		rules () {
			return {
				userName: this.userNameRules,
				password: this.passwordRules,
				imagecode:this.imagecodeRules,
			}
		}
	},
	methods: {
		handleSubmit () {
			this.$refs.loginForm.validate((valid) => {
				if (valid) {
					this.$emit('on-success-valid', {
						userName: this.form.userName,
						password: this.form.password,
						imagecode: this.form.imagecode
					})
					this.request()
				}
			})
		},
		request(){
			this.spinShow=true
			this.$Loading.start();
			let param = new URLSearchParams()
			param.append('username', this.form.userName)
			param.append('password', this.form.password)
			param.append('type', 1)
			return request({
				url:'login',
				method:"post",
				data:param
			}).then(response  => {
				var that = this
				this.spinShow=false
				this.$Loading.finish();
				if(response.code=="200"){
					this.$Message.success({
						content:"登录成功",
						duration:1.5,
						onClose:function(){				
							sessionStorage.setItem('login-token',response.data.token);
							sessionStorage.setItem('activeName',"1"); 
							sessionStorage.setItem('isLogin',true); 
							localStorage.setItem('user_name', response.name)
							that.$router.push('welcome');
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.$Loading.error();
					this.spinShow=false
					this.$Message.error({
						content:"系统异常，请稍后重试",
						duration: 3
					})
					this.$Message.error("")
				}
			})
		},
	},

}
</script>