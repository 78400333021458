import service from "components/system/service/list.vue";
import agent from "components/system/agent/list.vue"
const system = [
	{path: '/system/menu',name:'home', component: () => import('components/system/menu/list.vue'),children:[
		{path: '/system/menu/create',name:'menu/create', component: () => import('components/system/menu/create.vue')},
		{path: '/system/menu/edit',name:'menu/edit', component: () => import('components/system/menu/edit.vue')},
	]},
	{path: '/system/role',name:'system/role', component: () => import('components/system/role/list.vue'),children:[
		{path: '/system/role/jurisdiction',name:'role/jurisdiction', component: () => import('components/system/role/jurisdiction.vue')},
		{path: '/system/role/create',name:'role/create', component: () => import('components/system/role/create.vue')},
		{path: '/system/role/edit',name:'role/edit', component: () => import('components/system/role/edit.vue')},
	]},
	{path: '/system/industry',name:'home', component: () => import('components/system/industry/list.vue')},
	{path: '/system/service',name:'system/service',component:service,children:[
		{path: '/system/service/detail/:id',name:'service/detail', component: () => import('components/system/service/detail.vue')},
	]},
	{path: '/system/agent/list',name:'system/agent/list',component:agent,children:[
		{path: '/system/agent/create',name:'service/create', component: () => import('components/system/agent/create.vue')},
		{path: '/system/agent/edit/:id',name:'service/edit', component: () => import('components/system/agent/edit.vue')},
		{path: '/system/agent/password/:id',name:'service/password', component: () => import('components/system/agent/password.vue')},
		// {path: '/system/service/detail/:id',name:'service/detail', component: () => import('components/system/service/detail.vue')},
	]}
	
]
export default system;
